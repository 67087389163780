@import "../../../../styles/index.scss";

.calendar_month-event_list {
  margin-top: 4px;
  height: calc(100% - 20px - 4px);
  overflow: auto;
  padding-right: 3px;
  
  &-item {
    background: #e9ecfc;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 4px 8px 4px 2px;
    margin: 0 2px 5px 2px;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 80% 20%;
    grid-template-areas:
            "title count"
            "time markers";
    justify-content: space-between;
    border: 1px solid #CED4DA;
    overflow: hidden;


    .title{
      flex-grow: 1;
      margin-right: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: bold;
      font-size: 10px;
      line-height: 15px;
      color: $textPrimary;
      display: block;
      grid-area: title;
    }

    .time{
      font-style: normal;
      font-weight: normal;
      min-width: 45px;
      font-size: 10px;
      line-height: 15px;      
      color: $textPrimary;
      display: block;
      grid-area: time;
    }

    .count {
      display: flex;
      align-items: center;
      padding: 0 6px;
      background: white;
      border-radius: 39px;
      max-width: 25px;
      font-size: 8px;
    }
  }

  &-today_event{
    border: 1px solid #CED4DA;
    background: $white;
    box-shadow: none;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 4px 8px 4px 2px;
    margin: 0 2px 5px 2px;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 80% 20%;
    grid-template-areas:
            "title count"
            "time markers";
    justify-content: space-between;

    .title {
      font-size: 10px;
      line-height: 15px;
      text-overflow: ellipsis;
      overflow: hidden;
      color: $textPrimary;
      display: block;
      grid-area: title;
      width: auto;
    }

    .time {
      color: $textPrimary;
      font-size: 10px;
      line-height: 15px;
      display: block;
      grid-area: time;
    }

    .count {
      display: flex;
      align-items: center;
      padding: 0 6px;
      background: #e9ecfc;
      border-radius: 39px;
      max-width: 25px;
      font-size: 8px;
    }
  }

  &-active{
    background: $primary;
    box-shadow: none;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 4px 8px 4px 2px;
    margin: 0 2px 5px 2px;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 80% 20%;
    grid-template-areas:
            "title count"
            "time markers";
    justify-content: space-between;

    .title{
      color: $white;
      font-size: 10px;
      text-overflow: ellipsis;
      line-height: 15px;
      display: block;
      grid-area: title;
      overflow: hidden;
    }

    .time {
      color: $white;
      font-size: 10px;
      line-height: 15px;
      display: block;
      grid-area: time;
    }

    .count {
      display: flex;
      align-items: center;
      padding: 0 6px;
      background: white;
      border-radius: 39px;
      max-width: 25px;
      font-size: 8px;
    }
  }
}

.markers{
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: markers;
  padding: 0 6px;

  &-item{
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
  &-not-signed{
    background: #495057;
  }
  &-not-completed{
    background: #FF3C3C;
  }
  &-signed{
    background: #2196F3;
  }
  &-invoiced{
    background: #4CAF50;
  }
}

.empty {
  height: 50%;
  width: 100%;
}