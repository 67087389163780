@import "../../../styles/index.scss";

.date_picker-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .date_picker-header--current_month_year {
    font-weight: bold;
    color: $textPrimary;
    font-family: $primaryFont;
    font-size: $h4;
    line-height: 24px;
    cursor: default;

    .date_picker-header--back_arrow{
      position: absolute;
      top: 8px;
      left: 8px;
      height: 20px;
      width: 20px;
      
    }
  }

  .date_picker-month_switcher {
    display: flex;
    align-items: center;
    margin-right: 32px;
    div {
      cursor: pointer;
      display: flex;
      align-items: center;
    }
  }
}
