@import "../../../../../../../styles/variables";

.reportForAdmin{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 20px;
    &__btn{
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
      & > div{
        width: 200px;
      }
    }
    &__schoolYear{
        grid-column: 1/3;
    }
    &__mandateTime{
        grid-column: 3/5;
    }

    &__filters-input {
        background-color: #FFF;
        width: 196px;
        position: relative;

        & .input__wrapper {
            position: relative;
            display: block;
            justify-content: space-between;
        }

        &  .drop {
          border: 1px solid #757F98;
          width: 195px;
          color: $textPrimary;
          background: white;

          & > .disabled {
            font-size: 13px;
            color: grey;
          }
        }

        &  .filter__input {
          width: 100%;
          border: 1px solid #757F98;
          border-radius: 4px;
          font-size: 13px;
          color: $textPrimary;
          height: 36px;
          text-indent: 10px;
          padding-right: 15px;
          white-space: nowrap;
          text-overflow: ellipsis;

          &::placeholder {
            color: $textPrimary;
          }
        }
      }
}
