@import "../../../styles/index.scss";

.calendar {
    &__nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 10px;
        &_mode {
            display: flex;
            justify-content: flex-start;

            .button {
                width: max-content;
                padding: 0;
                margin-right: 22px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
    &__button {
        width: 200px;
    }
}
