.reports {
    &-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: -8px;
    }

    &-item {
        width: calc(100% / 3 - 16px);
        margin: 8px;
        padding: 0;
        padding-top: 90px;
        border-radius: 4px;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='22' viewBox='0 0 24 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.6667 0.333324L10.6667 14.4477L6.27617 10.0572L4.39055 11.9428L11.4477 19H0.000341535C0.000341535 20.4727 0 21.6666 0 21.6666H24.0003V19H12.5523L19.6095 11.9428L17.7239 10.0572L13.3334 14.4477L13.3334 0.333324L10.6667 0.333324Z' fill='%23192A53'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: center 29px;
    }

    &-text {
        border-radius: 0px 0px 4px 4px;
        width: 100%;
        display: inline-block;
        background-color: #ff9500;
        font-weight: normal;
        font-size: 18px;
        line-height: 40px;
        text-align: center;
        color: #ffffff;
    }
}
