.student_invoice_validation {
  font-family: "Poppins", serif;
  font-size: 13px;
  color: #192A53;
  &__header {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr 1fr;
    grid-column-gap: 15px;
    padding: 16px 20px;
    &_item {
      padding-bottom: 12px;
      & span:nth-child(1) {
        margin-right: 5px;
        font-weight: 700;
      }
      & span:nth-child(2) {
        font-weight: 400;
      }
    }
  }
  &_horizont-line {
    border-bottom: 1px solid #ced4da;
  }
  &__buttons {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    margin-top: 24px;
    & .column-4 {
      grid-column-start: 4;
    }
  }
  &_button {
    padding-left: 24px;
  }
  &__body_errors {

  }
  &__label {
    width: 112px;
    background-repeat: no-repeat;
    background-position: left 10px center;
    background-size: 20px;
    background-color: #F44336;
    border-radius: 4px;
    padding: 8px 40px;
    color: #ffffff;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.16602 12.5001H10.8327V14.1667H9.16602V12.5001ZM9.16602 5.83342H10.8327V10.8334H9.16602V5.83342ZM9.99102 1.66675C5.39102 1.66675 1.66602 5.40008 1.66602 10.0001C1.66602 14.6001 5.39102 18.3334 9.99102 18.3334C14.5993 18.3334 18.3327 14.6001 18.3327 10.0001C18.3327 5.40008 14.5993 1.66675 9.99102 1.66675ZM9.99935 16.6667C6.31602 16.6667 3.33268 13.6834 3.33268 10.0001C3.33268 6.31675 6.31602 3.33341 9.99935 3.33341C13.6827 3.33341 16.666 6.31675 16.666 10.0001C16.666 13.6834 13.6827 16.6667 9.99935 16.6667Z' fill='white'/%3E%3C/svg%3E%0A");
  }
  &__content {
    font-weight: 500;
    padding-left: 20px;
    margin-top: 8px;
    max-width: 600px;
  }
  &__item {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    border-bottom: dashed #CED4DA 1px;
    padding: 8px 0;
  }
  
  &__item:last-of-type {
    border-bottom: none;
  }
  &__item > div:last-child {
    text-align: right;
  }
}
