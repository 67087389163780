.reportview {
  &__creation {
    padding-top: 14px;
    &-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 14px;
     
    }
    &-delete {
      cursor: pointer;
    }
    &-header {
      padding: 12px;
      border-radius: 4px;
      margin-bottom: 8px;
    }
    &-info {
      padding: 16px 12px;
      border-radius: 4px;
    }
  }
}