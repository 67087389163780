@import '../../../../../../styles/variables';
.admin-assignment-list{
  &-header{
      display: flex;
      justify-content: flex-start;
      padding: 0 0 10px 0;

      .button{
          width: max-content;
          padding: 0;
          margin-right:22px;

          &:last-child{
              margin-right: 0;
          }
      }
  }
  .table__th, .table__td {
      min-width: auto;
      flex: 1 1 10%;
      padding: 20px 10px;
      text-align: center;
  }
  &__status {
    display: flex; 
    justify-content: space-between;
  }
  &__filters {
    display: flex; 
    justify-content: flex-end;
    flex-grow: 1;
    & > div:first-child {
      display: flex;
      justify-content: flex-end; 
      width: 80%;
    }
  }
  &__datefield {
    width: 230px;
    height: 38px;
    margin-right: 32px;
    position: relative;
  }
  &__textfield{
      margin-right: 32px;
      width: 196px;
      background-color: #ffffff;
      position: relative;

      & .select select{
        padding-right: 47px;
      }
      & .input__wrapper {
        border-radius: 4px;
        border: 1px solid #757F98;
      }
      &  .drop {
        border: 1px solid #757F98;
        width: 100%;
        width: 195px;
        color: $textPrimary;
        background: white;
        & > .disabled {
          font-size: 13px;
          color: grey;
        }
      }
      &  .filter__input {
        width: 80%;
        border:none;
        font-size: 13px;
        color: $textPrimary;
        height: 36px;
        text-indent: 10px;
        padding-right: 15px;
        white-space: nowrap;
        text-overflow: ellipsis;
        &::placeholder {
          color: $textPrimary;
        }
        }
  }
  .select-reset{
    cursor: pointer;
    position: absolute;
    display: inline-flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
  .create-btn {
    // padding-right: 30px;
    width: 15%;
    display: flex;
    margin-left: auto;
    margin-bottom: 16px;
  }
 .reset-filter {
   width: 15%;
  //  padding-right: 30px;
   div {
     width: 100%;
     height: 100%;
     white-space: nowrap;
   }
 }
}

.modalWindowAssignment{
  & div.modal {
    width: 340px !important;
  }
  &__text{
    margin: 0 0 40px 0;
    color: #192A53;
    font-family: "Poppins";
    font-weight: 500;
    font-size: 13px;
  }
}
