.provider-home {
  &__wrapper {
    display: flex;
    justify-content: space-between;
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 22px 18px;
    margin-bottom: 16px;
    overflow-wrap: break-word;
    &-time {
      padding-bottom: 10px;
      display: flex;
      align-items: center;
    }
    &-point {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 26px;
      height: 16px;
      background-color: #ff9602;
      margin-left: 8px;
      border-radius: 39px;
      padding: 2px 4px;
      &-text{
        font-family: "Poppins";
        font-style: normal;
        //font-weight: bold;
        font-size: 8px;
        line-height: 12px;
        color: #FFFFFF;
      }
    }
    &-status {
      width: 10%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;
      & div {
        color: #192A53;
        font-size: 12px;
        font-weight: 600;
        overflow-wrap: anywhere;
      }
      & div.hideBtn{
        color: rgba(25, 42, 83, 0.6);
        padding-top: 20px;
        &:hover{
          cursor: pointer;
          opacity: 0.9;
        }
      }
    }
  }
}

.providerHomePage{
  &__btn{
    display: flex;
    & span.button-content {
      & span{
        font-weight: bold;
    }
    }
  }
}