.headerpage {
  min-height: 67px;
  padding-bottom: 20px;
  &__breadcrumbs {
    padding-bottom: 10px;
  }
}

.last_breadcrumbs:last-child {
  font-weight: bold;
}
