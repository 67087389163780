.textarea-container {
  height: 232px;
  overflow: auto;
  padding-bottom: 24px;
  & > div:first-child {
    height: 100%;
    & textarea {
      height: 100%;
    }
  }
}

.time-container {
  display: flex;
  padding-bottom: 60px;
  & > div:last-child {
    .select {
      display: block;
    }
  }
}