.student-service {
  &__title {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
  }
  &__button {
    width: 200px;
  }
  &__content {
    & .table.contrast .table__head,
    .table.contrast .table__row {
      display: grid;
      grid-template-columns: 140px 135px 149px 111px 145px 240px 122px 123px 140px 142px 101px 105px 100px 200px;
    }
    & .table.contrast .table__sticky{
      background: white;
    }
    & .table.contrast .table__row.null > div{
      display: flex;
      align-items: center;
    }
  }
}

.modal {
  width: 350px;
  max-height: 500px;
  overflow: auto;
  position: absolute;
  left: 50%;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.1);
    transform: translateX(-50%) translateY(-50%);
  top: 50%;
  // left: calc(50% - 350px/2);
  background-color: #ffffff;
  z-index: 10;
  border-radius: 4px;
  outline: none;
  &__closeicon {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    cursor: pointer;
  } 
  &__wrapper {
    padding: 34px 20px;
    &-field {
      padding: 12px 0px;
      label {
        font-size: 13px;
        color: #192A53;
        font-weight: 500;
        &.error {
          color: #ff5454;
        }
      }
      span[name=label] {
        display: inline-block;
        padding-bottom: 5px;
        font-size: 13px;
        color: #192A53;
        font-weight: 500;
        &.error {
          color: #ff5454;
        }
      }
      div[name=radio] {
        width: 150px;
        padding-top: 10px;
      }
    }
    &-pickers {
      display: flex;
      position: relative;
      justify-content: space-between;
      .picker__wrapper {
        width: 43%;
      }
      .picker__hr {
        position: absolute;
        top: 8px;
        left: calc(50% - 10px);
        hr {
          width: 20px;
          height: 2px;
          background-color: #495057;
        }
      }
    }
    &-label {
      font-size: 13px;
        color: #192A53;
        font-weight: 500;
    }
    &-error {
      color: #ff5454;
    }
    &-errortext {
      display: inline-block;
      color: #ff5454;
      padding-top: 5px;
      padding-left: 10px;
      font-size: 13px;
    }
    &-radiogroup {
      display: flex;
      width: 150px;
      padding-top: 7px;
      & label {
        padding-left: 5px
      }
    }
  }
}

.modal {
  width: 350px;
  max-height: 500px;
  overflow: auto;
  position: absolute;
  left: 50%;
  background-color: #ffffff;
  z-index: 10;
  border-radius: 4px;
  outline: none;
  &__closeicon {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    background-color: #F9F9F9;
    cursor: pointer;
  } 
  &__wrapper {
    padding: 34px 20px;
  }
}
