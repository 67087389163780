.provider_profile{
  &__field {
    padding: 12px 10px;
    &.button {
      padding-top: 27px;
    }
  }
  &__container {
    display: flex;
    justify-content: space-between;
    & > div:first-child {
      width: 67%;
    }
  }
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  &__checkboxes {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  &__buttons {
    max-height: 85px;
  }
}
.isTextArea {
  height: 250px
}

.checkbox {
  display: flex;
  cursor: pointer;
  &__box {
    min-width: 18px;
    width: 18px;
    height: 18px;
    border: 1px solid #adb5bd;
    border-radius: 4px;
    transition: background-color 0.2s ease-out;
    margin-bottom: 0;
  }
  &__checked {
    display: flex;
    justify-content: center;
    border: 1px solid #ff9500;
    align-items: center;
    background-color: #FF9500;
  }
  &__label {
    width: calc(100% - 18px);
    margin-left: 5px;
    color: #192A53;
    font-size: 13px;
    line-height: 20px;
    white-space: pre-wrap;
    word-wrap: break-word;
    &-error {
      color: #ff5454;
    }
  }
  &__disabled {
    color: #676767;
    background-color: #e0e0e0;
  }
}

.checkbox_profile {
  display: flex;
  cursor: default;
  &__box {
    min-width: 18px;
    width: 18px;
    height: 18px;
    border: 1px solid #adb5bd;
    border-radius: 4px;
    transition: background-color 0.2s ease-out;
    margin-bottom: 0;
  }
  &__checked {
    display: flex;
    justify-content: center;
    border: 1px solid #ff9500;
    align-items: center;
    background-color: #FF9500;
  }
  &__label {
    width: calc(100% - 18px);
    margin-left: 5px;
    color: #192A53;
    font-size: 13px;
    line-height: 20px;
    white-space: pre-wrap;
    word-wrap: break-word;
    &-error {
      color: #ff5454;
    }
  }
  &__disabled {
    color: #676767;
    background-color: #e0e0e0;
  }
}