.invoices {
  &__list-status {
    display: flex;
    justify-content: space-between;
    min-width: 200px;
  }
}

.whitespace {
  white-space: nowrap;
}