.provider-students-list {
    &-header {
        display: flex;
        justify-content: flex-start;
        padding: 0 0 25px 10px;

        .button {
            width: max-content;
            padding: 0;
            margin-right: 22px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
    .table__th,
    .table__td {
        min-width: auto;
        flex: 1 1 10%;
        padding: 20px 10px;
        text-align: center;
    }

    .provider__textfield {
        margin-left: auto;
        width: 300px;
        margin-right: 32px;
        background-color: #ffffff;
    }
}

.itemBold {
    font-weight: bold;
    color: #192A53;
}
