@import "../../../styles/index.scss";

.drawer_item {
  display: flex;
  width: 200px;
  padding: 13px 20px 13px 9px;
  border-radius: 4px;
  //height: 42px;
  .drawer_item-link {
    display: flex;
    align-items: center;
    transition: all $defaultTransitionDuration linear;
    &.aсtive{
      .drawer_item-link-name {
        color: #FFFFFF;
        font-weight: bold;
      }
    }
    .drawer_item-link-name {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #192A53;
      font-family: $primaryFont;
      margin-left: 16px;
      display: block;
    }
  }
  &.pre-item {
    padding-left: 40px;
  }
}
