@import "../../../styles/index.scss";


.drawer__container-header{
  display: flex;
  align-items: center;
  .drawer__container-header-title{
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #192A53;
    font-family: $primaryFont;
  }

  .drawer__container-header-arrow{

  }
}