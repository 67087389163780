.provider {
  &__nav {
    display: flex;
    padding-bottom: 10px;
    &__left {
      display: flex;
      margin-right: auto;
      :first-child {
        padding: 0;
      }
    }
    &__right {
      display: flex;
      justify-content: flex-end;
      &__search {
        margin-right: 12px;
        width: 300px;
      }
    }
  }
}

.itemBold {
  font-weight: bold;
  color: #192A53;
}
