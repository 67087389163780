.header{
    height: 94px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 60px 15px 20px;

    &__logo{
        max-width: 187px;
        max-height: 50px;
        margin-right: 38px;
    }

    &__time{
        margin-right: auto;
        padding-left: 30px;
        position: relative;
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        display: flex;
        align-items: center;
        color: #192A53;


        &::before{
            position: absolute;
            left: 0;
            top:50%;
            transform: translateY(-50%);
            content: '';
            background: #BDBDBD;
            height: 50px;
            width: 1px;
        }
    }

    &__subscribe{
        width: 56px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #F9F9F9;
        border-radius: 10px;
    }

    &__profile{
        margin-left: 34px;
        border: 1px solid #E0E0E0;
        border-radius: 10px;
        display: flex;
        position: relative;
    }
    &__options {
      outline: none;
      width: 100%;
      border-radius: 10px;
      position: absolute;
      bottom: -45px;
      padding: 10px 15px;
      background-color: #ffffff;
      div {
        display: flex;
        justify-content: space-between;
        width: 100%;
        cursor: pointer;
        span {
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          display: flex;
          align-items: center;
          color: #192A53;
          opacity: 0.6;
        }
      }
    }
    &__photo{
        width: 56px;
        height: 56px;
        background-color: #72C8CC;
        text-align: center;
        padding-top: 16px;
        padding-bottom: 16px;
        font-weight: 600;
        border-radius: 8px 0 0 8px;
        overflow: hidden;
        color: #ffffff;
        font-size: 16px;
        line-height: 24px;
        text-transform: uppercase;
    }

    &__data{
        padding: 8px 60px 8px 16px;
        position: relative;

        &::after{
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 9L12 15L18 9' stroke='%23828282' stroke-width='2'/%3E%3C/svg%3E%0A");
            width: 24px;
            height: 24px;
            position: absolute;
            top: 50%;
            right: 10px;
            content: '';
            transform: translateY(-50%);
        }
    }

    &__name{
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: #192A53;
        word-break: break-word;
    }

    &__id{
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #192A53;
        opacity: 0.6;
    }
}