.systemRole {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
}

.roleItem {
    background: #ffffff;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    &__edit {
        cursor: pointer;
    }
    &__title {
        font-weight: 600;
        font-size: 18px;
        font-family: "Poppins";
        font-style: normal;
        color: #192A53;
    }
}
.grid-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 45px 45px 45px 45px 90px 45px 45px 45px 45px 90px 45px 45px 45px 45px 90px;
    grid-column-gap: 41px;
}