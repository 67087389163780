

// Font Family
$primaryFont: 'Poppins', sans-serif;

// Color

$surface: #E5E5E5;
$surfaceSecondary: #F9F9F9;
$white: #FFFFFF;
$black: #000000;
$primary: #FF9500;
$secondary: '';
$blend1: #FFEACC;
$error: #ff5454;
$disabled: #e0e0e0;
$textDisabled: #676767;
$textPrimary: #192A53;
$textSecondary: #495057;
$lightSecondary: #666666;
$grey: #828282;
$darkGrey: #E6E6E6;
$borderLine: #757F98;
$textGray: #4F4F4F;

// button text color;

$buttonContainedTextPrimary: #ffffff;
$buttonOutlinedTextPrimary: #142452;
$buttonOutlinedTextSecondary: #192A53;
$buttonDefaultTextPrimary: #FF9500;
$buttonDefaultTextSecondary: #192A53;
$buttonDefaultTextGrey: #495057;
$buttonTextDisabled: #ffffff;

// button outline

$buttonOutlinedBorder: #192A53;

// input text color

$textInputColor: #192A53;

// select text color

$textSelectColor: #495057;

// button background color

$buttonContainedPrimary: #FF9500;
$buttonDisabled: #CED4DA;
$buttonContainedGrey: #EDEDED;
$buttonContainedSuccess: #4CAF50;

// label color

$labelPrimary: #2196F3;
$labelSuccess: #4CAF50;
$labelDefault: #495057;
$labelDanger: #F44336;


// checkbox backhround color



// font-size

$h1: 24px;
$h3: 18px;
$h4: 16px;
$subtitle1: 14px;
$subtitle2: 13px;


// transitions

$defaultTransitionDuration: 0.2s
