.addNewAuthorization {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
    align-items: center;

    &-checkbox{
        &-wrapper{
            width: 100%;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
    }
}
.modal__wrapper {
    &__field {
        span {
            font-size: 13px;
        }

        &.double-field {
            grid-column: 1/-4;
        }
    }
}
