.provider {
    &__row {
        width: 50%;
        padding-bottom: 16px;
        &--6 {
            width: 50%;
            padding-bottom: 0;

            &:nth-child(1),
            &:nth-child(2) {
                padding-bottom: 100px;
            }
        }
    }
}
.provider_profile {
    &__container-error {
        padding: 5px;
        span {
            color: #ff5454;
            font-size: 14px;
        }
    }
}

.rightSideOptions {
    display: flex;
    flex-direction: column;
    div.button {
        width: 300px;
    }
}

.signatureContainer {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    &__item {
        width: 250px;
        height: 40px;
        background: #edf1f3;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__name {
        color: #192A53;
        font-family: "Poppins", serif;
        font-size: 13px;
    }
}

.info__container{
    padding-right: 30px;
    & .provider {
    &__row {
      &--6 {
        width: 50%;
        padding-bottom: 0;
  
        &:nth-child(1),
        &:nth-child(2) {
          padding-bottom: 100px;
        }
      }
    }
  }
  .rightSideOptions {
    display: flex;
    flex-direction: column;
    & > div:first-child {
      height: auto;
      width: 100%;
    }
  
    &-btn{
      & + & {
        margin-top: 10px;
      }
    }
  
    & > div:last-child {
      height: auto;
      width: 100%;
      margin-top: 18px;
    }
    &__field {
      padding-bottom: 18px;
    }
    &__button {
      padding-top: 18px;
    }
  }
}

.change_password {
  background: white;
  padding: 25px 35px;
}
