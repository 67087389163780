.student-assignment{
  &__fields {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }
  &__field {
    padding: 12px 10px;
    &.halfWidth {
      width: 200%;
    }
    .checkbox {
      // justify-content: flex-end;
    }
    &.active .checkbox {
      justify-content: flex-start;
    }
    &_gc3 {
      grid-column: 3
    }
  }
}
