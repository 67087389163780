@import "../../../../styles/index.scss";

.custom_reccurence_dialog {
  &-content {
    &_field {
      &__label {
        color: $textPrimary;
        padding-bottom: 8px;
        display: block;
      }
      &_repeat-every {
        margin-bottom: 20px;
        &_field {
          display: flex;
          gap: 8px;

          &-count {
            width: 60px;
            .input {
              input {
                text-align: center;
                text-indent: 0;
              }
            }
          }
          &-unit {
            width: 128px;
          }
          &-error {
            &-container {
              height: 20px;
              padding-top: 2px;
            }
            &-text {
              color: $error;
              font-size: 12px;
            }
          }
        }
      }

      &_repeat-on {
        margin-bottom: 24px;
        min-width: 272px;
        &_field {
          display: flex;
          align-items: center;
          gap: 8px;
          &-day {
            border: 1px solid $buttonDisabled;
            display: inherit;
            align-items: inherit;
            justify-content: center;
            border-radius: 50%;
            width: 32px;
            height: 32px;
            font-size: 12px;
            color: $textSelectColor;
            cursor: pointer;
            transition: all $defaultTransitionDuration linear;

            &:hover {
              opacity: 0.7;
            }

            &.active {
              color: $white;
              border: unset;
              background-color: $buttonDefaultTextPrimary;
            }
          }
        }
      }

      &_ends {
        &_field {
          &-radio{
            width: 100px;
          }
          &-on {
            margin-bottom: 4px;
            display: flex;
            align-items: center;
            &_date {
              width: 95px;
            }
          }
          &-after {
            display: flex;
            align-items: center;
            &_occurences {
              display: flex;
              align-items: center;
              &-label {
                font-weight: 500;
                font-size: 13px;
                line-height: 19px;
                color: $textPrimary;
                padding-left: 8px;
              }
              .input {
                width: 37px;
                input {
                  text-align: center;
                  text-indent: 0;
                }
              }
            }
          }
        }
      }
    }
    &_button {
      margin: 20px 0 30px;
    }
  }
}
