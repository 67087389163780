@import '../../../styles/variables';
.admin-invoices-list-header {
  display: flex;
  justify-content: space-between;
  &__filters {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    column-gap: 12px;
    row-gap: 9px;
    & > .admin-invoices-list-header__textfield {
      background-color: #FFF;
      width: 196px;
      position: relative;
      &  .drop {
        border: 1px solid #757F98;
        width: 195px;
        color: $textPrimary;
        background: white;
        & > .disabled {
          font-size: 13px;
          color: grey;
        }
      }
      & .input__wrapper {
        border-radius: 4px;
        border: 1px solid #757F98;
      }
      &  .filter__input {
      width: 80%;
      border:none;
      font-size: 13px;
      color: $textPrimary;
      height: 36px;
      text-indent: 10px;
      padding-right: 15px;
      white-space: nowrap;
      text-overflow: ellipsis;
      &::placeholder {
        color: $textPrimary;
      }
      }
    }
    &-row {
      display: flex;
    }
  }
  &__left {
    display:flex;
    align-items: flex-end;
  }
  &__status {
    display: flex;
  }
  &__right > div:last-child {
    width: 100%;
  }


}
.select-icon {
  position: absolute;
  right: 12px;
  top: 8px;
}
.admin-invoices-list {
  &__status {
    display: flex;
    justify-content: flex-start;
    & > .button {
      margin-right: 32px;
      margin-top: 16px;
      margin-bottom: 10px;
      width: fit-content;
      padding: 0;
    }
  }
  & .table__th, & .table__td {
    min-width: auto;
  }

  & .checkbox {
    height: 18px;
    width: 18px;
    border-radius: 4px;
  }
  & .select-reset {
    cursor: pointer;
    position: absolute;
    display: inline-flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
}
.table__th .checkbox__box {
  border-color: #FFF;
}
.rightSide-button-description {
  margin-bottom: 16px;
  color:#192A53;
}
.reset-filter {
  margin-left: 12px;
}
.email-preview {
  &_wrapper {
    background-color: #FFF;
    padding: 30px 20px;
    margin-bottom: 20px;
    border-radius: 4px;
    border: 1px solid #F2F2F2;
  }
  &_content {
    display: flex;
  }
  &.leftSideOptions {
    width: 68%;
    margin-right: 3%;
  }
  &_info {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 16px;
    padding-bottom: 16px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    margin-bottom: 8px;
    & .input .textfield, & .textarea .textfield {
      border: none;
    }
    & .textarea {
      width: 100%;
    }
    & .textarea .textfield {
      text-indent: 0;
    }
    &-error {
      color: $error;
      text-align: center;
    }
}
&_input-wrapper {
  width: 30%;
}
&_input-wrapper:last-of-type {
  width: 80%;
}
&_attachments {
  width: 100%;
}
&_add-attachment-button {
  margin-top: 10px;
  max-width: 130px;
}
&_delete-email-button {
  max-width: 300px;
  margin-left: auto;
  margin-top: 16px;
}
&_attachments {
  margin-top: 10px;
  &-item {
    background-color: #EDF1F3;
    display: flex;
    padding: 8px 8px;
    width: 60%;
    color: #192A53;
    align-items: center;
    margin-right: 9px;
  }
  &-item > svg {
    margin-right: 4px;
  }
  & > div {
    display: flex;
    align-items: center;
  }
}
&_icon {
  cursor: pointer;
}
}
.row {
  display: flex;
  align-items: center;
  padding-top: 8px;
    padding-bottom: 8px;
 }
 .status-label {
    background-repeat: no-repeat;
    background-position: left 10px center;
    background-size: 20px;
    border-radius: 4px;
    padding: 4px 8px 4px 8px;
    color: #ffffff;
    max-width: 141px;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    height: auto;

  &.primary {
    background-color: $labelPrimary;
  }
  &.success {
    background-color: $labelSuccess;
  }
  &.default {
    background-color: $labelDefault;
  }
}
