.react-pdf {
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 24px 10px 10px;
        position: fixed;
        width: 100vw;
        height: 56px;
        background-color: rgb(41, 41, 41);
        top: 0;
        left: 0;
        z-index: 11;
    }
    &__background {
        position: fixed;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.6);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 5;
    }
    &__body {
        position: absolute;
        z-index: 10;
        top: 56px;
        width: 100%;
        left: 0;
        display: flex;
        justify-content: center;
    }
    &__modal {
        width: 340px;
        position: fixed;
        top: 60px;
        right: 5px;
        background-color: #FFFFFF;
        z-index: 2000;
        &-header {
            width: 100%;
            background-color: #F9F9F9;
            height: 36px;
        }
        &-container {
            padding: 32px 20px 30px 20px;
            &_title {
                display: inline-block;
                width: 280px;
                font-size: 24px;
                color: #192A53;
                font-weight: 600;
            }
            &_input {
                padding-bottom: 47px;
                padding-top: 24px;
            }
        }
    }
}

.button-preview {
    width: 480px;
    display: flex;
    & > div:first-child {
        border: 1px solid #ffffff;
        color: #ffffff;
        margin-right: 16px;
    }
}

.secure-code-buttons {
    & > div:last-child {
        border: 0;
        color: #192A53;
        margin-top: 5px;
    }
}

.imagePreview {
    width: 100%;
    height: 100%;
    &__signature {
        width: 90px;
        height: 70px;
        position: absolute;
        top: 3.5%;
        left: 35%;
    }
}
