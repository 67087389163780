.studentInvoicesList {
    font-size: 13px;
    font-family: "Poppins", serif;
    color: #192A53;
    &__status {
        color: #ffffff;
        font-weight: 500;
        font-size: 14px;
        width: 128px;
        height: 29px;
        border-radius: 4px;
        padding: 4px 16px 4px 12px;
    }
}
.sent {
    background: #2196f3;
}
.pending {
    background: #495057;
}
.rejected {
    background: #FF3C3C;
}
.signed {
    background: #4CAF50;
}
.flex-column {
    width: 100%;
}