.student-goal {
  &__nav {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
  }
  &__button {
    width: 200px;
  }
  &__textfield {
    width: 20%;
  }
}