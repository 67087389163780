.login {
  &__page {
    background: #f5f5f5;
    height: calc(100vh - 94px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__container {
    padding: 32px 28px 0px 24px;
  }
}

.login__text-field {
  span.textfield__label {
    padding-bottom: 10px;
  }
}
