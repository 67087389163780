.create_session {
  &__fields {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    position: relative;
    & div:first-child {
      grid-column: 1 / -4;
    }
    & div:last-child {
      grid-column: 1 / -4;
    }
  }
  &__field {
    position: relative;
    padding: 12px 10px;

    & .datefield {
      & input {
        height: 40px;
      }
    }

    &.goals {
      display: grid;
      width: 100%;
      grid-template-columns: 1fr 0.1fr;
      align-items: end;
    }

    &.double-field {
      grid-column: 1 / -4;
      display: block;
    }

    &.button {
      text-align: center;
      padding-top: 32px;
    }
    & div.button{
      display: flex;
    }
  }
  &__buttons {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-start: 3;
    align-items: flex-end;
    height: 135px;
  }
  &__button {
    padding: 12px 10px;
    &-first {
      padding: 12px 10px;
      grid-column-start: 3;
    }
  }
  &__error {
    color: #ff5454;
    font-size: 0.75em;
    text-align: center;
  }
  &__divider {
    position: absolute;
    right: -5px;
    top: 45px;
  }
}

.timepicker-container {
  position: relative;
  & input {
    color: #192A53;
    font-size: 12px;
    height: 19px;
  }
}

