.callLog{
    &__label{
        padding-bottom: 5px;
        font-weight: bold;
        font-family: "Poppins";
        font-size: 13px;
        line-height: 19px;
        color: #192A53;
    }
    &__hide{
        display: none;
    }
    &__open{
        display: block;
    }
} 