@import "src/styles/variables";
.invoice_view {
  display: flex;
  justify-content: space-between;
  &__label {
    height: 29px;
    background-repeat: no-repeat;
    background-position: left 10px center;
    background-size: 20px;
    border-radius: 4px;
    padding: 6px 36px;
    color: #ffffff;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.16602 12.5001H10.8327V14.1667H9.16602V12.5001ZM9.16602 5.83342H10.8327V10.8334H9.16602V5.83342ZM9.99102 1.66675C5.39102 1.66675 1.66602 5.40008 1.66602 10.0001C1.66602 14.6001 5.39102 18.3334 9.99102 18.3334C14.5993 18.3334 18.3327 14.6001 18.3327 10.0001C18.3327 5.40008 14.5993 1.66675 9.99102 1.66675ZM9.99935 16.6667C6.31602 16.6667 3.33268 13.6834 3.33268 10.0001C3.33268 6.31675 6.31602 3.33341 9.99935 3.33341C13.6827 3.33341 16.666 6.31675 16.666 10.0001C16.666 13.6834 13.6827 16.6667 9.99935 16.6667Z' fill='white'/%3E%3C/svg%3E%0A");
    margin-top: 30px;
    &.primary {
      background-color: $labelPrimary;
    }
    &.success {
      background-color: $labelSuccess;
    }
    &.default {
      background-color: $labelDefault;
    }
    &.danger {
      background-color: $labelDanger;
    }
  }
  &__container {
    display: flex;
    margin-top: 16px;
  }
  &__left {
    width: 68%;
    color: $textPrimary;
  }
  &__right {
    width: 370px;
    margin-left: 40px;
    margin-top: 86px;
  }
  &__pdf_preview {
    &__content {
      min-width: 350px;
      min-height: 500px;
      border: 1px solid #E2E2E2;
    }
    &__header {
      height: 50px;
      background: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0));
      display: flex;
      justify-content: right;
      padding: 8px;
    }
    &__body {
      display: flex;
      justify-content: center;
      padding: 10px;
      & .react-pdf__Document {
        & .react-pdf__Page {
          & canvas {
            width: 350px !important;
            height: 500px !important;
          }
          & div.react-pdf__Page__textContent {
            opacity: 0;
          }
        }
      }
    }
    &__footer {
      display: flex;
      justify-content: center;
      background-color: #EDEDED;
      height: 36px;
      color: #192A53;
      cursor: pointer;
      & p {
        align-self: center;
      }
    }
    &__open_icon {
      width: 24px;
      height: 24px;
      align-self: center;
      cursor: pointer;
    }
  }
  &__pdf_info {
    margin-left: 30px;
    &__item {
      margin-bottom: 8px;
    }
  }
}
.flex {
  display: flex;
}
