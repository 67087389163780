@import "../../../../../styles/variables";
.provider-sessions-list {
  &-header {
    margin-bottom: 10px;
    &__right {
      display: flex;
    }
    &__filters {
      margin-left: auto;
      display: flex;
    }
    &__textfield {
      background-color: #FFF;
      width: 196px;
      height: 36px;
      position: relative;
      &:not(:last-child) {
        margin-right: 12px;
      }
      &  .drop {
        border: 1px solid #757F98;
        width: 195px;
        color: $textPrimary;
        background: white;
        & > .disabled {
          font-size: 13px;
          color: grey;
        }
      }
      &  .filter__input {
      width: 80%;
      border: none;
      border-radius: 4px;
      font-size: 13px;
      color: $textPrimary;
      height: 36px;
      text-indent: 10px;
      padding-right: 15px;
      white-space: nowrap;
      text-overflow: ellipsis;
      &::placeholder {
        color: $textPrimary;
      }
      }
      & > div > .input__wrapper {
        border: 1px solid #757F98;
        border-radius: 4px;
      }
    }
    &__datefield {
      width: 240px;
      height: 36px;
      margin-right: 12px;
      position: relative;
    }

    & .select-reset {
      cursor: pointer;
      position: absolute;
      display: inline-flex;
      width: 20px;
      height: 20px;
      justify-content: center;
      align-items: center;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }
    & .reset-filter {
      width: 140px;
    }
  }
  & .table__th, & .table__td {
    min-width: auto;
  }
  & .table__th:last-child {
    min-width: 188px;
  }
  & .table__head > div:nth-child(2),
  .table__row > div:nth-child(2){
    min-width: 188px;
  }
}
.calendar-day-event-status {
  font-size: 14px;
  line-height: 21px;
  padding: 4px 12px;
  margin-left: 0;
}
.status-container {
  width: 140px;
}
