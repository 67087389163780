.confirm-modal {
  min-width: 264px;
  min-height: 230px;
  max-width: 340px;
  background-color: #ffffff;
  position: fixed;
  border-radius: 4px;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 10000;
  &__title {
    padding-bottom: 40px;
  }
  &__wrapper {
    padding: 68px 20px 30px;
  }

  &::before{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 36px;
    background: #F9F9F9;
    border-radius: 4px 4px 0px 0px;
    content: '';
  }
}
.grey-screen {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 15;
}
