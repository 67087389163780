.content {
  background-color: #ffffff;
  border-radius: 4px;
  
  &__wrapper {
    padding: 24px 20px;
  }
  &__title {
    color: #FF9500;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 32px;
  }
  &__icon {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    & + & {
      margin-left: 12px;
    }

    &:first-of-type{
      margin-left: auto;
    }
  }
}

.buttons {
  width: 30%;
  height: 100%;
  border-radius: 4px;
  background-color: #ffffff;
  &__wrapper {
    padding: 24px 35px;
    &-field {
      padding: 5px 0px;
    }
  }
}