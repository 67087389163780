@import '../../../../styles/variables';

.sessions-list{
  &-header{
    margin-bottom: 10px;

    &-filters {
      display: flex;
      justify-content: flex-end;

      & > .sessions-list-filters-input-date {
        background-color: #FFF;
        width: 240px;
        position: relative;
        margin-right: 12px;
      }

      & > .sessions-list-filters-input {
        background-color: #FFF;
        width: 196px;
        position: relative;
        margin-right: 12px;

        &  .drop {
          border: 1px solid #757F98;
          width: 100%;
          width: 195px;
          color: $textPrimary;
          background: white;

          & > .disabled {
            font-size: 13px;
            color: grey;
          }
        }

        &  .filter__input {
          width: 100%;
          border: 1px solid #757F98;
          border-radius: 4px;
          font-size: 13px;
          color: $textPrimary;
          height: 36px;
          text-indent: 10px;
          padding-right: 50px;
          white-space: nowrap;
          text-overflow: ellipsis;

          &::placeholder {
            color: $textPrimary;
          }
        }
      }
    }
  }
}

.allSessionAdmin {
  & .table__head > div:nth-child(9), 
  .table__row > div:nth-child(9){
    min-width: 175px;
  }
  & .table__head > div:nth-child(2), 
  .table__row > div:nth-child(2){
    min-width: 175px;
  }
}
  
.itemBold {
  font-weight: bold;
  color: #192A53;
}

.select-reset{
  cursor: pointer;
  position: absolute;
  display: inline-flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  background-color: white;
}
