.providerDocuments {
  & .table__head,
  .table__row{
  display: grid;
  grid-template-columns: 60% 20% 20%;
  }
  &__item{
    display: flex;
    align-items: center;
    height: 100%;
  }
}