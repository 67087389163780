.provider {
  &__row {
    &--6 {
      width: 50%;
      padding-bottom: 0;

      &:nth-child(1),
      &:nth-child(2) {
        padding-bottom: 100px;
      }
    }
  }
}
.rightSideOptions {
  display: flex;
  flex-direction: column;
  & > div:first-child {
    height: auto;
    width: 100%;
  }

  &-btn{
    & + & {
      margin-top: 10px;
    }
  }

  & > div:last-child {
    height: auto;
    width: 100%;
    margin-top: 18px;
  }
  &__field {
    padding-bottom: 18px;
  }
  &__button {
    padding-top: 18px;
  }
}
