.table {
  width: calc(100% - 32px);
  border-spacing: 0;
  border: none;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  line-height: 19px;
  margin-right: -32px;
  max-height: calc(100vh - 385px);

  &.first-full &{
    &__th,
    &__td {
      min-width: auto;
      flex: 1 1 20%;

      &:first-child{
        flex: 1 1 100%;
      }
    }
  }

  &.without-btns &{
    &__row-wrapper{
      margin-right: 0;
    }
  }

  &.contrast & {
    &__head {
      > div {
        color: #ffffff;
        background-color: #ff9500;

        &:before {
          background: #ffffff;
        }
      }
    }

    &__row-wrapper {
      &:nth-child(2n + 2) {
        .table__row {
          > div {
            background-color: #fff4e5;
          }
        }
      }
    }
  }

  &__wrapper{
    margin-right: -32px;
  }
  &__head {
    display: flex;
    position: sticky;
    // margin-right: 32px;
    top: 0;
    z-index: 2;
  }

  &__row-wrapper {
    display: flex;
    margin-right: -32px;
  }

  &__row {
    position: relative;
    display: flex;
    flex-grow: 1;
    box-sizing: border-box;
    &.isTableForClick:hover {
      border: 3px solid #FF9500;
      border-radius: 4px;
      cursor: pointer;
    }
  }

  &__th,
  &__td {
    flex: 1 1 25%;
    min-width: 260px;
    text-align: left;
    padding: 20px 24px;
    background-color: #ffffff;
    vertical-align: top;
    position: relative;
    word-break: break-word;

    &:not(:first-child):before {
      position: absolute;
      left: 0;
      top: 50%;
      content: "";
      transform: translateY(-50%);
      height: 44px;
      width: 1px;
    }
  }
  &__th:not(:first-child):before {
    background: #FFFFFF;
    opacity: 0.2;
    
  }
  &__td:not(:first-child):before {
    background: #192A53;
    opacity: 0.6;
    height: calc(100% - 15px);
  }
  &__btns {
    display: flex;
    position: absolute;
    right: 0;
    border-radius: 4px;

    // filter: drop-shadow(0px 2px 14px rgba(0, 0, 0, 0.1));

    &.open {
      .table__btn--hide {
        width: 32px;
        opacity: 1;
        &:before {
          opacity: 1;
        }
      }

      .table__btn-dots {
        transform: rotate(90deg);
      }
      
      .table__btn {
        background: #fff;
        svg {
          fill:#192A53;
        }
        &:hover {
          background-color: #EDEDED;
          fill:#192A53;
        }
        &:focus {
          background: #FF9500;
        svg {
          fill: #fff;

        }
        }
      }
      
    }
     
  }

  &__btn {
    background: none;
    border-radius: 4px;
    position: relative;
    width: 32px;
    
    height: 32px;
    display: inline-block;
    padding: 0;
    transition: width 0.2s ease;
    &:hover {
      background-color: #EDEDED;
    }

    &--hide {
      width: 0;
      opacity: 0;
    }

    &_shadow {
      filter: drop-shadow(0px 2px 14px rgba(0, 0, 0, 0.1));
    }

    &:not(:last-child) {
      &:before {
        opacity: 0;
        transition: opacity 0.2s ease;
        position: absolute;
        width: 1px;
        height: 20px;
        background-color: #c4c4c4;
        content: "";
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &__btn-dots {
    transition: transform 0.2s ease;
    fill:#192A53;
  }

  &__th {
    position: sticky;
    top: 0;
    font-style: normal;
    font-weight: bold;
    color: #192A53;
    border-spacing: 0;
    z-index: 2;
  }

  &__td {
    border-top: 1px solid #757F98;
    color: #192A53;
  }

  &__sticky {
    min-width: 32px;
    position: sticky;
    z-index: 1;
    right: 0;
    height: inherit;
    display: flex;
    align-items: center;
    top: 0;
    bottom: 0;
  }

  &__service {
    width: 36px;
    height: 79px;
    background: #f9f9f9;
    z-index: 30;
    position: fixed;
    right: 0;
    top: 273px;
  }

  &__context {
    position: absolute;
    right: 32px;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border-radius: 4px;
    width: 200px;
    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.1);
    display: none;
    z-index: 100;

    &.open {
      display: flex;
    }

    &-link {
      background: #ffffff;
      border-radius: 4px;
      text-align: left;
      font-size: 13px;
      line-height: 19px;
      color: #192A53;
      padding: 8px 12px;
      position: relative;
      transition: background-color 0.2s ease;

      &:hover {
        background: #EDEDED;
        &::after {
          opacity: 0;
          visibility: hidden;
        }
      }
      &:focus {
        background: #FF9500;
        color: #fff;
        &::after {
          opacity: 0;
          visibility: hidden;
        }
      }

      &:last-child {
        &::after {
          display: none;
        }
      }

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 172px;
        height: 1px;
        background: #192A53;
        opacity: 0.6;
        transition: opacity 0.2s ease, visibility 0.2s ease;
      }

      &_close {
        margin-left: auto;
        display: inline-flex;

        &:after {
          display: none;
        }
      }
    }
  }
}
.showData__btn {
  padding-top: 5px;
  svg {
    fill:#192A53;
  }
  &:hover {
    background: #EDEDED;
  }
}
.empty {
  color: #192A53;
  margin-top: 24px;
  margin-bottom: 17px;
  display: flex;
  justify-content: center;
  font-size: 13px;
}

@media (max-width: 1015px) {
  .table {
    &__service {
      background: none;
    }
  }
}