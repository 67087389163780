@import "../../styles/index.scss";

.date_picker {
  background-color: $white;
  margin-top: -24px;
  .date_picker-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .date_picker-header--current_month_year {
      font-weight: bold;
      color: $textPrimary;
      font-family: $primaryFont;
      font-size: $h4;
    }

    .date_picker-month_switcher {
      display: flex;
      align-items: center;
      div {
        cursor: pointer;
        display: flex;
        align-items: center;
      }
    }
  }

  .date_picker-calendar {
    margin-top: 22px;
  }
}
