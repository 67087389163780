.school {
  &__schoolNameField {
    margin-bottom: 40px;
  }
  &__btn {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
  }
}

.hide {
  display: none;
}

.show {
  display: block;
}
