.addNewGoal{
    width: 70%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
    &__title{
        grid-column: 1/3;
    }
    &__code{
        grid-column: 3/5;
    }
    &__text{
        grid-column: 1/5;
        & div.textarea{
            & textarea{
                min-height: 230px;
            }
        }
    }
}

.preview{
    background: #FFFFFF;
    margin-top: 20px;
    width: 100%;
    padding: 15px;
    &__title{
        text-align: start; 
        padding-bottom: 20px;
        color: #192A53;
        font-weight: bold;
        font-family: "Poppins";
        font-size: 16px;
    }
    &__content{
        color: #192A53; 
        font-size: 13px; 
        line-height: 19px; 
        color: #192A53;
    }
    .input {
        display: inline;
        & input {
            width: 50px;
            margin-top: 5px;
            height: 30px;
        }
      }
      .persent{
        .input {
            display: inline;
            & input {
                width: 50px;
                margin-top: 5px;
                height: 30px;
            }
        }
    }
}

.long{
    .input {
        display: inline;
           & input {
            width: 250px;
            margin-top: 5px;
            height: 30px;
            }
      }
}

.askAnswer{
    & .select__wrapper, .select{
        display: inline;
        & select {
            width: 250px;
            margin-top: 5px;
            height: 30px;
            background-position-y: 1px;
      }
    }
}

.placeholders{
    width: 20%;
    border-left: 1px solid #CED4DA;
    height: min-content;
    & div{
        color: #192A53;
        text-align: center;
        font-weight: bold;
        font-family: "Poppins";
        font-size: 16px;
    }
}

.container{
    background: #FFFFFF; 
    padding: 25px 20px 60px 20px;
}