.studentDocumentsTable {
  & .table__td {
      padding: 10px 24px;
  }
  & .table__row-wrapper {
      display: block;
  }
  & .table__row-wrapper {
    margin-right: 0;
    & .table__td {
        min-width: 50px;
    }
}
    & .table__head,
    .table__row {
        display: grid;
        grid-template-columns: 50% 25% 25%;
    }
    &__name {
        justify-content: flex-start;
        align-items: center;
        display: grid;
        grid-template-columns: 30px 1fr 1fr 1fr 1fr 1fr;
        & span {
            padding-left: 12px;
            grid-column: 2/7;
        }
        & svg {
            grid-column: 1/2;
        }
    }  
    &__date{
        display: flex;
        height: 100%;
        align-items: center;
    }
}

.actions {
  &__dowload {
      margin-right: 10px;
      display: flex;
      align-items: center;
  }
}

.uploadFile {
  visibility: hidden;
  display: none;
}