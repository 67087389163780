.signer{
    &__page{
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 100%;
        width: 100%;
    }
    &__container {
        padding-bottom: 20px;
        filter: drop-shadow(0px 2px 14px rgba(0, 0, 0, 0.1));
    }
    &__img {
        max-width: 260px;
        border: 3px blue dashed;
    }
    &__img-preview {
        width: 60px;
        position: absolute;
    }
    &__success {
        background-color: #4CAF50;
    }
    &__signature_downloaded {
        color: #FFFFFF;
        font-family: "Poppins", serif;
        font-size: 13px;
    }
}
.ihoiu_parent {
    bottom: 150px;
    right: 380px;
}
.both_enhanced_parent {
    bottom: 130px;
    right: 260px;
}
.ihoiu_school {
    bottom: 70px;
    right: 380px;
}
.both_enhanced_school {
    bottom: 130px;
    right: 90px;
}
.enhanced {
    bottom: 155px;
    right: 130px;
}
.default_signature {
    bottom: 50px;
    right: 50px;
}
