.goalsDictionaries{
    background: #ffffff;
    padding: 30px 20px;
    &__btn{
        display: flex;
        width: 100%;
        &__left{
            width: 35%;
            & div{
                width: 170px;
            }
        }
        &__right{
            width: 50%;
            display: flex;
            & div{
                display: flex; 
                align-items: center;
            }
            & > :nth-child(1){
                margin: 0 10px 0 20px;
            }
            & > :nth-child(2){
                margin-right: 10px;
            }
            & > :nth-child(3){
                margin-right: 10px;
            }
        }
    }
    &__info{
        width: 100%;
        display: flex;
        padding-top: 20px;
        &__sidebar{
            border-right: 1px solid rgb(228, 225, 225);
            width: 35%;
            height: 700px;
            cursor: pointer;
        }
        &__container{
            width: 65%;
            height: 700px;
        }
        &__goals{
            color: #192A53; 
            font-size: 13px; 
            font-family: "Poppins"; 
            margin: 16px 24px; 
            line-height: 19px;
            & > div{
                display: flex; 
                justify-content: space-between;
                & > :nth-child(2){
                    display: flex; 
                    align-items: center; 
                    gap: 8px;
                    cursor: pointer;
                }
            }
            & > div.line{
                border-bottom: 1px solid  #CED4DA; 
                padding-top: 16px;
            }
        }
    }   
    & div.groupName{
        margin: 0 24px;
        color: #192A53;
        font-size: 24px; 
        font-weight: bold;
        font-family: "Poppins";
        overflow-wrap: break-word;
    }
    & div.info{
        width: 80%;
        & > div{
            margin-bottom: 5px;
            width: 100%;
        }
    }
}

.modalWindowGoals{
    & div.modal {
        width: 340px !important;
    }
    &__text{
        margin: 0 0 40px 0;
        color: #192A53;
        font-family: "Poppins";
        font-weight: 500;
        font-size: 13px;
        line-height: 130%;
    }
}

.goalsModalWindow{
    &__btn{
        width: 300px;
        margin: 16px auto 6px auto;
    }
    &__firstLevel{
        display: flex;
        align-items: center; 
        font-family: "Poppins";
        color: #192A53;
        line-height: 130%;
    }
}

.goalsGroup{
    font-family: "Poppins";
    color: #192A53; 
    font-size: 16px;
    margin-bottom: 8px;
    line-height: 130%;
    word-break: break-word;
    &__title{
        display: grid; 
        grid-template-columns: 1fr 1fr 1fr 1fr;
        width: 100%;
        & span:first-child{
            grid-column: 1/5; 
            overflow-wrap: break-word;
        }
        & span:last-child{
            color: #192A53;
            font-size: 13px;
            font-family: "Poppins";
            grid-column: 5/6; 
            margin: auto 10px auto 10px;
        }
    }
    &__icon{
        display: flex;
        margin-right: 5px;
        cursor: pointer;
    }
    &__arrowDown{
        display: flex; 
        padding: 10px 0;
        align-items: center; 
        cursor: pointer;
    }
    &__dotIcon{
        display: flex; 
        padding: 10px 0;
        align-items: center; 
    }
    &__titleGroup{
        font-family: "Poppins";
        color: #192A53;
        font-size: 16px;
        line-height: 130%;
    }
}

.goalsSubGroup{
    margin: 0 0 12px 20px;
    display: none;
    word-break: break-word;
    &__icon{
        display: flex;
        margin-right: 5px;
        cursor: pointer;
    }
    &__title{
        display: grid; 
        grid-template-columns: 1fr 1fr 1fr 1fr;
        width: 100%;
        & span:first-child{
        font-family: "Poppins"; 
        color: #192A53; 
        font-size: 16px;
        grid-column: 1/5;
        overflow-wrap: break-word;
        }
        & span:last-child{
            color: #192A53;
            font-size: 13px;
            font-family: "Poppins";
            grid-column: 5/6; 
            margin: auto 10px auto 10px;
        }
    }
}

.goalsSubGroupContainer{
    padding: 6px 0 6px 20px;
    cursor: pointer;
    &__icon{
        display: flex;
        margin-right: 5px;
        cursor: pointer;
    } 
    &__name{
        font-family: "Poppins"; 
        color: #192A53; 
        font-size: 16px;
        line-height: 130%;
    }
}

.goalsSubGroupContainer .openSubgroup{
    cursor: pointer;
}

.inActiveGroupGoals{
    font-weight: normal;
}

.activeGroupGoals{
    font-weight: bold;
}

.checkBoxView{
    margin-bottom: 24px;
    position: absolute;
}
