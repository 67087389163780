.generate-goal {
  .input {
    display: inline;
    & input {
      width: 50px;
      margin-top: 5px;
      height: 30px;
    }
  }
}

.generate-goal.text50{
  .input {
    display: inline;
    & input {
      width: 250px;
      margin-top: 5px;
      height: 30px;
    }
  }
}

.generate-goal.enumAskAnswer{
  & .select__wrapper, .select{
    display: inline;
    & select {
      width: 250px;
      margin-top: 5px;
      height: 30px;
      background-position-y: 1px;
  }
}
}