.table__th,
.table__td {
    min-width: auto;
}
.student-service__table {
    & .modal {
        max-height: 80vh;
    } 
}
.users_password-textfield {
    position: relative;
    margin-bottom: 24px; 
    width: 100%;
}
.users_password-icon {
    position: absolute;
    top: 30px;
    right: 12px;
}
.hide {
    display: none;
  }
  
  .show {
    display: block;
  }
.cancel-button {
    margin-top: 4px;
}