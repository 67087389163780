@import "../../../styles/index.scss";

.date_picker-calendar-table {
  width: 100%;
  border-spacing: 0;
  thead {
    tr {
      margin-bottom: 5px;
      th {
        color: $textPrimary;
        font-size: $subtitle1;
        font-family: $primaryFont;
        width: 32px;
        height: 32px;

      }
    }
  }
  tbody {
    tr {
      td {
        height: 32px;
        width: 32px;
        padding: unset;
        .day {
          font-family: $primaryFont;
          font-size: $subtitle1;
          color: $textPrimary;
          display: flex;
          align-items: center;
          justify-content: center;
          height: inherit;
          cursor: pointer;

          &.default {
            &:hover {
              background: $blend1;
              border-radius: 100px;
            }
          }

          &.active_range {
            background: $primary;
            color: $white;

            &_left {
              border-radius: 100px 0px 0px 100px;
              color: $white;
              background: $primary;
            }
            &_right {
              border-radius: 0px 100px 100px 0px;
              color: $white;
              background: $primary;
            }
            &_subitem {
              color: $white;
              background: $primary;
            }
          }

          &.active {
            background: $primary;
            color: $white;
            border-radius: 50%;
          }
          &.disabled {
            opacity: 0.4;
            cursor: auto;
          }
        }
      }
    }
  }
}
