.report {
  &__info {
    display: flex;
    & div:first-child {
      padding-right: 25px;
    }
      &-text {
        margin-bottom: 10px;
      }
  }
} 