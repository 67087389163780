@import '../../../../styles/variables';
.messages_textfield {
  margin-bottom: 20px;
    &  .filter__input {
      width: 100%;
      border: 1px solid #757F98;
      border-radius: 4px;
      font-size: 13px;
      color: $textPrimary;
      height: 36px;
      text-indent: 10px;
      padding-right: 50px;
      white-space: nowrap;
      text-overflow: ellipsis;
      &::placeholder {
        color: $textPrimary;
      }
  }
  &  .drop {
    border: 1px solid #757F98;
    width: 100%;
    height: 100px;
    color: $textPrimary;
    background: white;
    & > .disabled {
      font-size: 13px;
      color: grey;
    }
  }
}
.messages_button {
  margin-top: 40px;
}

.adminMessages{
  & .table__head,
  .table__row{
  display: grid;
  grid-template-columns: 9% 131px 23% 57%;
  }
  &__item{
    display: flex;
    align-items: center;
    height: 100%;
  }
  & .modal {
    max-height: 550px;
} 
& .filter__input.textfield__error {
  border: 1px solid #ff5454;
}
}