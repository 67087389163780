.schoolRepresentatives {
    &__btn {
        width: 280px;
        height: 36px;
        margin-bottom: 10px;
    }
    &__field {
        margin-bottom: 24px;
    }
    &__save{
        margin-top: 40px;
    }
    & .modal {
        max-height: 580px;
    }
}

.hide {
    display: none;
}
  
.show {
    display: block;
}
