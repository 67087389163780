.student-list{
  &-header{
    display: flex;
    margin-bottom: 10px;

    &-left{
      display: flex;
      margin-right: auto;

      .button{
        padding: 0;

        + .button{
          margin-left: 22px;
        }
      }
    }

    &-right{
      display: flex;
    }

    &-search{
      width: 300px;
      margin-right: 12px;
    }
  }
}