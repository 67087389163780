@import "../../../../../styles/index.scss";

.session_notes_form {
  &__header {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    position: relative;
    border-bottom: 1px solid $borderLine;
    padding: 0 20px 16px;
    &-item {
      padding-right: 56px;
      &_label {
        font-weight: bold;
        font-size: 13px;
        color: $textPrimary;
      }
      &_value {
        font-size: 13px;
        color: $textPrimary;
      }
    }
  }

  &_date {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding: 17px 20px;
    border: solid 4px #FFFFFF;
    border-radius: 4px;
    &-container {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      width: 100%;
    }
    &-icon {
      position: absolute;
      right: 0;
    }
    & .grey-screen:hover, .modal.custom-scroll:hover {
      cursor: auto;
    }
  }

  &_fields {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  &_field {
    position: relative;
    padding: 12px 10px;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    gap: 25px;
    padding-top: 48px;
    margin-right: 30px;
    & .blue .button {
      border: 1px solid #192A53;
      color: $textPrimary;
    }
    & .column-3 {
      grid-column-start: 3;
    }

    div {
      width: 195px;
    }
  }
  &__title {
    &-buttons {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      & .grey .button {
        border: 1px solid #192A53;
      }
    }
  }
  &__button {
    padding: 12px 10px;
    min-width: 160px;
    &-first {
      padding: 12px 10px;
      // grid-column-start: 2;
    }
  }
  &_title {
    padding: 32px 20px 3px;
  }
  &_section {
    width: 100%;
    padding: 24px 20px;
    border: solid 4px #FFFFFF;
    border-radius: 4px;
    &-title {
      display: flex;
      justify-content: space-between;
      color: $textPrimary;
      font-size: 13px;
      font-weight: bold;
      &-container {
        display: grid;
        grid-template-columns: 3fr 2fr;
        width: 100%;
        & h3 {
          align-self: center;
          font-size: 18px;
        }
      }
      &-label {
        color: #ffffff;
        font-size: 14px;
        height: 29px;
        border-radius: 4px;
        padding: 6px 16px 4px 12px;
        margin-left: 15px;
        min-width: fit-content;
        &.signed {
          background: #2196f3;
        }
        &.invoiced {
          background: #4CAF50;
        }
      }
      &-name {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      & .grey-screen:hover, .modal.custom-scroll:hover {
        cursor: auto;
      }
    }

    & span::first-letter {
      text-transform:capitalize;
    }

    &.no-border {
      border-bottom: unset;
    }

    &-content {
      color: $textPrimary;
      font-size: 13px;
      display: flex;
      &-prompt {
        color: $textPrimary;
        font-size: 13px;
        &_item {
          display: flex;
          align-items: center;
          gap: 5px;
        }
      }

      p {
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &-info {
        display: flex;
        justify-content: space-between;
        &_item {
          &_label {
            font-weight: bold;
            color: $textPrimary;
            font-size: 13px;
            display: block;
          }
          &_value {
            color: $textPrimary;
            font-size: 13px;
            display: block;
            padding-top: 8px;
          }
        }
        & .grey-screen:hover, .modal.custom-scroll:hover {
          cursor: auto;
        }
      }
      &-goals {
        width: 100%;

        &-list {
          width: 100%;
          color: $textPrimary;
          &-item {
            width: 100%;
            display: flex;
            gap: 20px;
            margin-top: 16px;
            &:first-child {
              margin-top: 0;
            }
            &_progress {
              background: $primary;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 52px;
              font-size: 13px;
              color: $white;
              position: relative;
              height: 20px;
              &:after {
                content: "";
                width: 0;
                height: 0;
                border-top: 9px solid transparent;
                border-bottom: 9px solid transparent;
                border-left: 9px solid $primary;
                position: absolute;
                right: -6px;
              }
              &-container {
                display: flex;
                align-items: flex-start;
              }
            }
            &_desc {
              width: calc(100% - 72px);
              font-size: 13px;
              line-height: 19px;
              color: $textPrimary;
              //   white-space: nowrap;
              //   overflow: hidden;
              //   text-overflow: ellipsis;
              white-space: pre-wrap;
              word-wrap: break-word;
            }
          }
        }
      }
    }
    &-button {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  &_dropdown {
    align-self: center;

    &:hover {
      cursor: pointer;
    }
    &.rotate {
      transform: rotate(180deg);
    }
  }
  &_horizont-line {
    border-bottom: 1px solid #ced4da;
  }
}

.border {
  &:hover {
    border: solid 4px #FF9500;
    border-radius: 4px;
    cursor: pointer;
  }
}

.goal-container {
  width: 360px;
  max-height: 70vh;
  overflow: scroll;
  &::-webkit-scrollbar {
    width: 10px;
  }
}

.skill-select {
  & .select__addbtn {
    width: 12%;
  }
}

.prompt-selects {
  width: 300px;
  display: flex;
  justify-content: space-between;
  & > div:first-child {
    width: 45%;
  }
  & > div:nth-child(2) {
    width: 50%;
  }
  &.new {
    & > div:last-child {
      .select__addbtn {
        width: 15%;
      }
    }
  }
}

.info-section {
  padding-bottom: 20px;
}

.MuiAutocomplete-root fieldset {
  border-color: $textPrimary !important;
}
.MuiFormLabel-root {
  color: $textPrimary !important;
}
.MuiChip-root {
  color: $textPrimary !important;
}
.hide {
  display: none;
}
