.student_profile {
  display: flex;
    &__container {
      width: 68%;
    margin-right: 3%;
    background-color: #ffffff;
    border-radius: 4px;
    }
    &__title {
      color: #FF9500;
      display: flex;
      justify-content: space-between;
      padding-bottom: 32px;
    }
    &__icon {
      cursor: pointer;
    }
    &__fields {
      display: grid;
    grid-template-columns: repeat(5, 1fr);
    }
    &__field {
      padding: 12px 10px;
    }
    &__big_field {
      padding: 12px 10px;
      grid-column-start: 2;
      grid-column-end: 4;
    }
    &__buttons {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
    }
}