.modalCallLog {
    width: 340px;
    overflow: auto;
    position: absolute;
    z-index: 20;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    background-color: #ffffff;
    border-radius: 4px;
    outline: none;
    &__closeicon {
      display: flex;
      justify-content: flex-end;
      padding: 10px;
      background-color: #F9F9F9;
      cursor: pointer;
    } 
    &__wrapper {
      padding: 34px 20px 30px 20px;
    }
  }
  
  .grey-screen {
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0.8;
    z-index: 15;
    position: fixed;
    background-color: #b3b3b3a8;
  }