.goalGroup {
  padding: 24px 0;

  & + & {
    border-top: 1px #757F98 solid;
  }

  &:first-child{
    padding-top: 0;
  }

  &__title{
    font-weight: bold;
    font-size: 16.25px;
    line-height: 120%;
    color: #192A53;
  }

  &__container {
    display: flex;
    justify-content: space-between;


  }
}
.sub-group {
  padding: 20px 0 20px 24px;

  &__title{
    width: 85%;
    font-weight: bold;
    font-size: 16.25px;
    line-height: 120%;
    color: #192A53;
  }
  &__container {
    display: flex;
    justify-content: space-between;
  }
}
.goal {
  padding: 20px 0 20px 24px;
  position: relative;
  
  &::after{
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 140px;
    height: 1px;
    background: #757F98;
  }
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_text{
      width: calc(100% - 115px - 45px);
      display: flex;
      flex-direction: column;
    }
  }
}
.short-goal {
  padding: 20px 24px;
  &__container {
  }
}

.title {
  //display: inline-block;
}

.descr {
  display: inline-block;
  width: 85%;
}

.arrow {
  cursor: pointer;
}

.select-btn {
  & > div:first-child {
    padding-right: 14px;
  }
}