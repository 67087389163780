.studentAssView{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 25px;
    font-family: "Poppins";
    color:  #192A53;
    font-size: 13px;
    line-height: 19px;
    word-break: break-all;
    & div.checkbox {
        cursor: default;
    }
    &__item{
        display: flex;
        flex-direction: column;
    }
    &__value{
        font-weight: bold;
    }
    &__btn{
        grid-column: 5;
    }
}
