.calendar-day {
  &-events {
    padding-top: 32px;
    max-height: 470px;
    flex-grow: 1;
    margin-right: 40px;
  }

  &-wrapper {
    display: flex;
    justify-content: space-between;
  }

  &-empty {
    padding-top: 28px;
    text-align: center;
    flex-grow: 1;
  }

  &-event {
    color: #192A53;
    padding: 20px;
    border: 1px solid #ced4da;
    box-sizing: border-box;
    border-radius: 4px;
    &-container{
      margin-left: auto;
    }
    & + & {
      margin-top: 24px;
    }

    &-time {
      font-weight: 500;
      font-size: 16.25px;
      line-height: 19px;
    }
    &-type {
      font-size: 13px;
      line-height: 19px;
      padding: 8px 0 3px;
    }

    &-student {
      font-size: 13px;
      line-height: 19px;
      padding: 5px 0;
      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: 1fr 1fr;

      & + & {
        border-top: 1px dashed #ced4da;
      }

      &-name {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    &-status {
      padding: 2px 6px;
      font-size: 10px;
      line-height: 15px;
      border-radius: 4px;
      color: white;
      width: max-content;

      &-not-signed {
        background: #EDEDED;
        color: #495057;
      }
      &-not-completed {
        background: #ff3c3c;
      }
      &-signed {
        background: #2196f3;
      }
      &-invoiced {
        background: #4caf50;
      }
    }
  }

  &-datePicker {
    position: relative;

    .date_picker-header .date_picker-header--current_month_year .date_picker-header--back_arrow {
      display: none;
    }
  }
}
