body {
  font-family: $primaryFont;
}

input,
textarea {
  font-size: 13px;
  line-height: 19px;
  color: #192A53;
}

.button {
  width: 100%;
  position: relative;
  padding: 8px 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease-out;
  &:hover {
    opacity: 0.7;
  }
  & > span {
    width: 100%;
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    font-family: $primaryFont;
    span[name="text-content"] {
      padding: 0 5px;
    }
    span[name="text-counter"] {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 39px;
      background: #ffffff;
      width: 29px;
      height: 20px;
      padding: 0 10px;
      color: #ff9500;
    }
    span[name="icon"] {
      display: inherit;
      align-items: inherit;
      justify-content: inherit;
      img {
        width: 15px;
        height: 15px;
      }
    }
  }
  &__contained {
    &-primary {
      color: $buttonContainedTextPrimary;
      background-color: $buttonContainedPrimary;
    }
    &-secondary {
      border: 1px solid #142452;
    }
    &-grey {
      background-color: $buttonContainedGrey;
    }
    &-success {
      color: $buttonContainedTextPrimary;
      background-color: $buttonContainedSuccess;
    }
  }
  &__outlined {
    &-primary {
      color: white;
      background-color: inherit;
      border: 1px solid white;
    }
    &-secondary {
      color: $buttonOutlinedTextSecondary;
      background-color: inherit;
      border: 1px solid $buttonOutlinedTextSecondary;
    }
    &-grey {
      color: $buttonOutlinedTextPrimary;
      border: 1px solid $buttonOutlinedBorder;
    }
  }

  &__dashed {
    &-secondary {
      border: 1px dashed #828282;
      color: #495057;
    }
  }

  &__default {
    &-primary {
      color: $buttonDefaultTextPrimary;
      background-color: inherit;
    }
    &-secondary {
      color: $buttonDefaultTextSecondary;
      background-color: inherit;
    }
    &-grey {
      color: $buttonDefaultTextGrey;
    }
  }
  &__disabled {
    color: $textDisabled;
    background-color: $disabled;
  }
}

.input {
  width: 100%;
  position: relative;
  .textfield {
    width: 100%;
    height: 36px;
    color: $textInputColor;
    border: 1px solid #757F98;
    border-radius: 4px;
    text-indent: 10px;
    z-index: 5;
    &::placeholder {
      color: $textPrimary;
      opacity: 0.6;
    }
    &__text-indent {
      text-indent: 0;
      padding-left: 32px;
    }
    &__label {
      display: inline-block;
      padding-bottom: 5px;
      font-size: $subtitle2;
      color: $textPrimary;
      font-weight: 500;
      &-error {
        color: $error;
      }
    }
    &__error {
      border: 1px solid $error;
      &::placeholder {
        color: $error;
      }
    }
    &__errortext {
      display: block;
      color: $error;
      font-size: 11px;
      padding-top: 5px;
      padding-left: 10px;
    }
    &__disabled {
      color: $textDisabled;
      background-color: $disabled;
    }
  }
  span[name="input-icon"] {
    position: absolute;
    top: 18px - 17/2;
    left: 10px;
    z-index: 100;
    img {
      width: 17px;
      height: 17px;
    }
  }
}

.textarea {
  .textfield {
    width: 100%;
    height: 120px;
    color: $textInputColor;
    border: 1px solid #757F98;
    border-radius: 4px;
    text-indent: 10px;
    z-index: 5;
    resize: none;

    &__text-indent {
      text-indent: 35px;
    }
    &__label {
      display: inline-block;
      padding-bottom: 5px;
      font-size: $subtitle2;
      color: $textPrimary;
      font-weight: 500;
      &-error {
        color: $error;
      }
    }
    &__error {
      border: 1px solid $error;
      &::placeholder {
        color: $error;
      }
    }
    &__errortext {
      display: inline-block;
      color: $error;
      font-size: 11px;
      padding-top: 5px;
      padding-left: 10px;
    }
    &__disabled {
      color: $textDisabled;
      background-color: $disabled;
    }
  }
}

.checkbox {
  display: flex;
  cursor: pointer;
  &__box {
    min-width: 18px;
    width: 18px;
    height: 18px;
    border: 1px solid #192A53;
    border-radius: 4px;
    transition: background-color 0.2s ease-out;
    margin-bottom: 0;
  }
  &__checked {
    display: flex;
    justify-content: center;
    border: 1px solid #ff9500;
    align-items: center;
    background-color: $primary;
  }
  &__label {
    width: calc(100% - 18px);
    margin-left: 5px;
    color: $textPrimary;
    font-size: 13px;
    line-height: 20px;
    white-space: pre-wrap;
    word-wrap: break-word;
    &-error {
      color: $error;
    }
  }
  &__disabled {
    color: $textDisabled;
    background-color: $disabled;
  }
}

.radiobutton {
  width: 100%;
  display: flex;
  align-items: center;
  &__box {
    position: relative;
    width: 20px;
    height: 20px;
    border: 2px solid $primary;
    border-radius: 50%;
  }
  &__opacity {
    opacity: 0;
  }
  &__checked {
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: $primary;
    left: calc(50% - 6px);
    top: calc(50% - 6px);
    opacity: 1;
    transition: opacity 0.2s ease-out;
  }

  &__label {
    margin-left: 5px;
    color: $textPrimary;
    &-error {
      color: $error;
    }
  }
}

.select {
  &__wrapper {
    display: flex;
    justify-content: space-between;
    &-disabled {
      background-color: #e0e0e0;
    }
  }
  select {

    width: 100%;
    border: 1px solid #757F98;
    border-radius: 4px;
    font-size: 13px;
    color: $textPrimary;
    height: 36px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 5px;
    text-indent: 10px;
    padding-right: 15px;
    white-space: nowrap;
    text-overflow: ellipsis;
    &.error {
      border: 1px solid $error;
      color: $error;
    }
    &.withadd {
      width: 85%;
    }
  }
  &__label {
    display: inline-block;
    padding-bottom: 5px;
    padding-left: 5px;
    font-size: $subtitle2;
    color: $textPrimary;
    font-weight: 500;
    &-error {
      color: $error;
    }
  }
  &__addbtn {
    display: flex;
    width: 10%;
    align-items: center;
    span {
      display: flex;
      cursor: pointer;
    }
  }
  &__errortext {
    display: inline-block;
    color: $error;
    font-size: 11px;
    padding-top: 5px;
    padding-left: 10px;
  }
}

.datefield {
  width: 100%;
  position: relative;
  &__disabled {
    color: #676767;
    background-color: #e0e0e0;
  }
  &__label {
    display: inline-block;
    padding-bottom: 5px;
    font-size: $subtitle2;
    color: $textPrimary;
    font-weight: 500;
    &-error {
      color: $error;
    }
    &.error {
      color: $error;
    }
  }
  &__container {
    position: relative;
    &-icon {
      position: absolute;
      right: 10px;
      top: 25%;
      cursor: pointer;
    }
    &-field {
      width: 100%;
      height: 36px;
      color: $textInputColor;
      border: 1px solid #757F98;
      border-radius: 4px;
      text-indent: 10px;
      z-index: 5;
      cursor: inherit;
      &::placeholder {
        color: $textPrimary;
      }
    }
    &-error {
      border: 1px solid $error;
      color: $error;
      &::placeholder {
        color: $error;
      }
    }
  }
  &__errortext {
    display: inline-block;
    color: $error;
    padding-top: 5px;
    padding-left: 10px;
    font-size: 12px;
  }
}

.text {
  word-break: break-word;
  &__h1 {
    font-size: $h1;
    font-weight: 700;
    color: $textPrimary;
  }
  &__h3 {
    font-size: $h3;
    font-weight: 600;
    color: $textPrimary;
  }
  &__h4 {
    font-size: $h4;
    font-weight: 700;
    color: $textPrimary;
  }
  &__subtitle1 {
    font-size: $subtitle1;
    font-weight: 500;
    color: $textPrimary;
  }
  &__subtitle1-bold {
    font-size: $subtitle1;
    font-weight: 700;
    color: $textPrimary;
  }
  &__subtitle2 {
    font-size: $subtitle2;
    font-weight: 500;
    color: $textPrimary;
  }
  &__subtitle2-bold {
    font-size: $subtitle2;
    font-weight: 700;
    color: $textPrimary;
  }
  &__desibled {
    opacity: 0.6;
  }
}

.custom-scroll {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    width: 4px;
    background: #ced4da;
    border-radius: 21.5px;
  }
}

.w-mc {
  width: max-content;
}

/* для элемента input c type="radio" */
.custom-radio {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
/* для элемента label связанного с .custom-radio */
.custom-radio + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
  font-size: 13px;
  line-height: 19px;
}
/* создание в label псевдоэлемента  before со следующими стилями */
.custom-radio + label::before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 2px solid $primary;
  border-radius: 50%;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
}
/* стили при наведении курсора на радио */
.custom-radio:not(:disabled):not(:checked) + label:hover::before {
  border-color: $primary;
}
/* стили для активной радиокнопки (при нажатии на неё) */
.custom-radio:not(:disabled):active + label::before {
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
/* стили для радиокнопки, находящейся в фокусе */
.custom-radio:focus + label::before {
  // box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
/* стили для радиокнопки, находящейся в фокусе и не находящейся в состоянии checked */
.custom-radio:focus:not(:checked) + label::before {
  border-color: #80bdff;
}
/* стили для радиокнопки, находящейся в состоянии checked */
.custom-radio:checked + label::before {
  border-color: $primary;
  background-color: #ffffff;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='13' height='13' viewBox='0 0 13.5 13.5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='6' cy='6' r='6' fill='%23FF9500'/%3E%3C/svg%3E%0A");
}
/* стили для радиокнопки, находящейся в состоянии disabled */
.custom-radio:disabled + label::before {
  background-color: #e9ecef;
}
