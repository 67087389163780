@import "../../../styles/index.scss";

.date_picker-calendar-month_table {
  width: 100%;
  border-spacing: 0;

  tbody {
    tr {
      td {
        height: 50px;
        width: 75px;
        padding: unset;
        .month {
          font-family: $primaryFont;
          font-size: $subtitle1;
          color: $textPrimary;
          display: flex;
          align-items: center;
          justify-content: center;
          height: inherit;
          cursor: pointer;

          &.default {
            &:hover {
              background: $blend1;
            }
          }

          &.active {
            background: $primary;
            color: $white;
          }
        }
      }
    }
  }
}
