.pagination{
  margin-top: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  &-item{
    width: 32px;
    height: 32px;
    font-size: 13px;
    line-height: 19px;
    color: #192A53;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    border-radius: 4px;
    transition: border .2s ease;
    cursor: pointer;

    &.active{
      color: #FFFFFF;
      background: #FF9500;
    }
    &:hover{
      border: 1px solid #FF9500;
    }

    & + & {
      margin-left: 8px;
    }
  }

  &-prev{
    margin-right: 8px;
  }
  &-next{
    margin-left: 8px;
  }

  &-btn{
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg{
      fill:  #A5ACB3;
      transition: all .2s ease;
    }

    &:hover{
      svg{
        fill:  #FF9500;
      }
    }

    &-disabled{
      touch-action: none;
    }
  }
}