.student_profile {
  display: flex;
  &__container {
    width: 68%;
    margin-right: 3%;
    background-color: #ffffff;
    border-radius: 4px;
  }
  &__fields {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }
  &__field {
    padding: 12px 10px;
  }
  &__buttons {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }
}
.info {
  display: flex;
  flex-wrap: wrap;
  &__container {
    width: 350px;
    padding-bottom: 32px;
    &-data {
      padding-bottom: 7px;
      word-break: break-word;
      span {
        color: #192A53;
        font-size: 13px;
      }
      span {
        &:first-child {
          display: inline-block;
          padding-right: 10px;
        }
      }
    }
  }
}
.editmode {
  padding: 0px 16px 32px 16px;
}

.buttons__wrapper-field .button-content {
  display: flex;
  justify-content: space-between;
}
