@import "../../../styles/index.scss";
.calendar_month-table {
  width: 100%;
  border-spacing: 0;
  margin-top: 20px;
  box-sizing: border-box;

  td {
    width: 100px;
  }

  thead {
    tr {
      margin-bottom: 10px;
      th {
        color: $textPrimary;
        font-size: $subtitle1;
        font-family: $primaryFont;
        width: 152px;
        height: 32px;
        font-weight: normal;
      }
    }
  }
  tbody {
    tr {
      &:last-of-type{
        .event_tooltip-info{
          //margin-top: -80px;
        }
      }
      td {
        border: 1px solid $buttonDisabled;
        height: auto;
        padding: unset;
        position: relative;

        &:nth-of-type(n+5):nth-of-type(-n+7) {
          .event_tooltip-info{
            left: -1px;
            transform: translateX(-100%);
          }
        }

        .day {
          font-family: $primaryFont;
          font-size: $subtitle1;
          color: $textPrimary;
          height: 359px;
          padding: 0 3px;
          width: 100%;

          &.today {
            background: $darkGrey;
            .day_cell-today{
              position: absolute;
              top: 0;
              left: 5px;
            }
          }

          .day_cell-header {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            width: calc(100% + 6px);
            background: none;
            padding: 0;
            line-height: 20px;
            margin: 0 -3px;
            transition: background-color .2s ease;

            &:hover{
              background: #FFF4E5;
            }
            &.not-current-month {
              opacity: 0.4
            }
          }

          &.disabled {
            color: $buttonDisabled;
          }
          &.active {
            background: $primary;
            color: $white;
            border-radius: 50%;
          }
        }
      }
    }
  }
}

.empty {
  width: 100%;
}

