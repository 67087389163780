.snackbar {
    position: fixed;
    top: 110px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    visibility: hidden;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
    padding: 12px 50px;
    border-radius: 4px;
    background-repeat: no-repeat;
    background-position: left 20px center;
    background-size: 20px;
    animation-name: fade-in ;
    z-index: 1000;

    &.open {
        opacity: 1;
        visibility: visible;
    }

    &.error {
        background-color: #F44336;
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.16602 12.5001H10.8327V14.1667H9.16602V12.5001ZM9.16602 5.83342H10.8327V10.8334H9.16602V5.83342ZM9.99102 1.66675C5.39102 1.66675 1.66602 5.40008 1.66602 10.0001C1.66602 14.6001 5.39102 18.3334 9.99102 18.3334C14.5993 18.3334 18.3327 14.6001 18.3327 10.0001C18.3327 5.40008 14.5993 1.66675 9.99102 1.66675ZM9.99935 16.6667C6.31602 16.6667 3.33268 13.6834 3.33268 10.0001C3.33268 6.31675 6.31602 3.33341 9.99935 3.33341C13.6827 3.33341 16.666 6.31675 16.666 10.0001C16.666 13.6834 13.6827 16.6667 9.99935 16.6667Z' fill='white'/%3E%3C/svg%3E%0A");
    }
    &.primary {
        background-color: #ff9500;
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.56843 17.0001L10.0007 4.16261L17.4329 17.0001H2.56843Z' stroke='white' stroke-width='2'/%3E%3Cmask id='path-2-inside-1' fill='white'%3E%3Cpath d='M9.16602 15.4999H10.8327V13.8333H9.16602V15.4999Z'/%3E%3Cpath d='M9.16602 12.1666H10.8327V8.83325H9.16602V12.1666Z'/%3E%3C/mask%3E%3Cpath d='M10.8327 15.4999V17.4999H12.8327V15.4999H10.8327ZM9.16602 15.4999H7.16602V17.4999H9.16602V15.4999ZM9.16602 13.8333V11.8333H7.16602V13.8333H9.16602ZM10.8327 13.8333H12.8327V11.8333H10.8327V13.8333ZM10.8327 12.1666V14.1666H12.8327V12.1666H10.8327ZM9.16602 12.1666H7.16602V14.1666H9.16602V12.1666ZM9.16602 8.83325V6.83325H7.16602V8.83325H9.16602ZM10.8327 8.83325H12.8327V6.83325H10.8327V8.83325ZM10.8327 13.4999H9.16602V17.4999H10.8327V13.4999ZM11.166 15.4999V13.8333H7.16602V15.4999H11.166ZM9.16602 15.8333H10.8327V11.8333H9.16602V15.8333ZM8.83268 13.8333V15.4999H12.8327V13.8333H8.83268ZM10.8327 10.1666H9.16602V14.1666H10.8327V10.1666ZM11.166 12.1666V8.83325H7.16602V12.1666H11.166ZM9.16602 10.8333H10.8327V6.83325H9.16602V10.8333ZM8.83268 8.83325V12.1666H12.8327V8.83325H8.83268Z' fill='white' mask='url(%23path-2-inside-1)'/%3E%3C/svg%3E%0A");
    }
    &.information {
        background-color: #2196f3;
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.16602 12.5001H10.8327V14.1667H9.16602V12.5001ZM9.16602 5.83342H10.8327V10.8334H9.16602V5.83342ZM9.99102 1.66675C5.39102 1.66675 1.66602 5.40008 1.66602 10.0001C1.66602 14.6001 5.39102 18.3334 9.99102 18.3334C14.5993 18.3334 18.3327 14.6001 18.3327 10.0001C18.3327 5.40008 14.5993 1.66675 9.99102 1.66675ZM9.99935 16.6667C6.31602 16.6667 3.33268 13.6834 3.33268 10.0001C3.33268 6.31675 6.31602 3.33341 9.99935 3.33341C13.6827 3.33341 16.666 6.31675 16.666 10.0001C16.666 13.6834 13.6827 16.6667 9.99935 16.6667Z' fill='white'/%3E%3C/svg%3E%0A");
    }
    &.success {
        background-color: #4caf50;
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.99935 1.66675C5.40435 1.66675 1.66602 5.40508 1.66602 10.0001C1.66602 14.5951 5.40435 18.3334 9.99935 18.3334C14.5943 18.3334 18.3327 14.5951 18.3327 10.0001C18.3327 9.06175 18.1693 8.16192 17.8818 7.31942L16.5326 8.6687C16.6201 9.0987 16.666 9.54425 16.666 10.0001C16.666 13.6759 13.6752 16.6667 9.99935 16.6667C6.32352 16.6667 3.33268 13.6759 3.33268 10.0001C3.33268 6.32425 6.32352 3.33341 9.99935 3.33341C11.3602 3.33341 12.6259 3.74499 13.6826 4.44832L14.8757 3.25529C13.5023 2.26029 11.8202 1.66675 9.99935 1.66675ZM17.7435 2.74422L9.16602 11.3217L6.42188 8.57756L5.24349 9.75594L9.16602 13.6785L18.9219 3.92261L17.7435 2.74422Z' fill='white'/%3E%3C/svg%3E%0A");
    }
}

@keyframes fade-in {
    from {
        top: 130px;
        opacity: 0;
    }
    20% {
        top: 110px;
        opacity: 1;
    }
    90% {
        top: 110px;
        opacity: 1;
    }
    to {
        top: 130px;
        opacity: 0;
    }
}
