@import '../../styles/index.scss';

.wrapper{
  padding: 12px 15px;
}

.providerWrapper{
  padding: 12px 10px;
}

.label {
  display: inline-block;
  padding-bottom: 5px;
  padding-left: 5px;
  font-size: 13px;
  color: $textPrimary;
  font-weight: 500;
}

.input__wrapper {
  position: relative;

  display: flex;
  justify-content: space-between;
}

.city__input {
  width: 100%;
  border: 1px solid #757F98;
  border-radius: 4px;
  font-size: 13px;
  color: $textPrimary;
  height: 36px;
  text-indent: 10px;
  padding-right: 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.city__input-error {
  width: 100%;
  border: 1px solid #ff5454;
  border-radius: 4px;
  font-size: 13px;
  color: $textPrimary;
  height: 36px;
  text-indent: 10px;
  padding-right: 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.drop {
  position: absolute;
  width: 100%;
  max-height: 200px;
  overflow: auto;
  top: 35px;
  background: white;
  border: 1px solid #ced4da;
  z-index: 5;
}

.item {
  cursor: pointer;
  font-size: 13px;
  color: $textPrimary;
  line-height: 19px;

  &:hover {
    background: #2196f3;
  }
}

.city_error {
  font-size: 11px;
  color: #ff5454;
  padding-top: 5px;
  padding-left: 10px;
}

.city_label_error {
  display: inline-block;
  padding-bottom: 5px;
  padding-left: 5px;
  font-size: 13px;
  color: #ff5454;
  font-weight: 500;
}