.periodPanel {
  background-color: white;
  border: 1px solid #ced4da;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-family: "Poppins", serif;
  padding: 12px 12px 8px;
  flex-direction: column;
  width: 244px;
  margin-bottom: 10px;
  &_container__month {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 12px;
    & p {
      font-weight: bold;
      color: #192A53;
      font-size: 18px;
    }
    & svg {
      cursor: pointer;
    }
  }
  & .disabled {
    opacity: 0.5;
    & svg {
      cursor: auto;
    }
  }
  &_container__button {
    width: 100%;
  }
}
