.enter_session {
  &__fields {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    position: relative;
    & div:first-child {
      grid-column: 1 / -4;
    }
  }
  &__field {
    position: relative;
    padding: 12px 10px;
  }
}
