.horizont_button_bar {
  display: flex;
  padding: 10px 24px;
  background-color: rgba(0, 0, 0, 0.6);
  justify-content: flex-end;
}

.signer_signature {
  &__content {
    display: flex;
    padding-top: 40px;
    background-color: #E5E5E5;
  }
  &__right {
    position: absolute;
    right: 0;
    padding-right: 10px;
    & p {
      font-weight: bold;
      text-align: center;
      margin-bottom: 24px;
    }
  }
}
