.addNewStudentGoal {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
  &__field {
    grid-column: 1/3;
  }
  &__fieldRight {
    grid-column: 3/5;
  }
}