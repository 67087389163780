.report {
  display: flex;
  justify-content: space-between;
  & > div:first-child {
    width: 67%;
  }

  &__container {
    background-color: #ffffff;
  }
  &__creation {
    padding-top: 14px;
    &-abilities{
      width: 100%;
    }
    &-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 14px;
     
    }
    &-delete {
      cursor: pointer;
    }
    &-header {
      padding: 12px;
      border: 1px solid #CED4DA;
      border-radius: 4px;
      margin-bottom: 8px;
    }
    &-info {
      padding: 16px 12px;
      border: 1px solid #CED4DA;
      border-radius: 4px;
    }
  }
}