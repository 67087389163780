.main {

  &__content {
    display: flex;
    justify-content: space-between;
    background-color: #F5F5F5;
    min-height: calc(100vh - 94px);
    &-layout {
      width: 85%;
      min-width: 768px;
      height: 100%;
      .layout {
        &__container {
          width: 100%;
          padding: 58px 32px;
        }
      }
    }
    &-drawer {
      width: 15%;
      min-width: 250px;
      height: 100%;
      .drawer {
        &__container {
          width: 100%;
          height: 100%;
          padding: 32px 25px;
          &-header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding-bottom: 30px;
          }
          &-items {
            padding-left: 10px;
            div {
              span {
                &:first-child {
                  display: inline-block;
                  padding-right: 16px;
                }
              }
            }
          }
        }
      }
    }
  }

}
