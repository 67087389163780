.login{
    
    &__wrapper{
        max-width: 450px;
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0px 12px 24px rgba(18, 38, 63, 0.05);
        border-radius: 4px;
    }

    &__container{
        padding: 32px 28px 24px;
    }

    &__header{
        background: #F9F9F9;
        border-radius: 4px 4px 0px 0px;
        padding: 24px;
    }

    &__title{
        font-weight: 500;
        font-size: 16.25px;
        line-height: 19px;
    }

    &__text{
        display: block;
        margin-top: 8px;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 19px;
    }

    &__text-field{
        margin-bottom: 16px;
    }

    &__checkbox-field{
        margin-bottom: 16px;
        font-weight: 500;
        font-size: 13px;
        line-height: 19px;
    }

    &__btn{
        margin-bottom: 24px;
    }
}