@import "../../../../../styles/index.scss";

.event_tooltip-info {
  width: 270px;
  position: absolute;
  z-index: 2;
  right: -1px;
  top: 0;
  transform: translateX(100%);
  background: #ffffff;
  border: 1px solid #ced4da;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px 12px;

  &-top {
    margin-top: -80px;
  }

  &-boottom {
    margin-top: 160px;
  }

  &-time_range {
    font-weight: 500;
    font-size: 16.25px;
    line-height: 19px;
    color: $textPrimary;
  }

  &-sub_title {
    font-size: 13px;
    line-height: 19px;
    color: #192A53;
    padding: 8px 0px;
  }

  &-description {
    font-size: 13px;
    line-height: 19px;
    color: #192A53;
  }
}

.event_tooltip-info_left {
  width: 270px;
  position: absolute;
  z-index: 2;
  right: 390px;
  top: 0;
  transform: translateX(100%);
  background: #ffffff;
  border: 1px solid #ced4da;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px 12px;

  &-time_range {
    font-weight: 500;
    font-size: 16.25px;
    line-height: 19px;
    color: $textPrimary;
  }

  &-sub_title {
    font-size: 13px;
    line-height: 19px;
    color: #192A53;
    padding: 8px 0px;
  }

  &-description {
    font-size: 13px;
    line-height: 19px;
    color: #192A53;
  }
}
