@import '../../styles/index.scss';

.dialog_content {
  background: $white;

  &-title{
    padding-bottom: 24px;
  }
  .dialog_content-header{
    height: 36px;
    background: $surfaceSecondary;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 8px;

    .dialog_content-header--close{
      cursor: pointer;
      display: inherit;
      align-items: inherit;
    }
  }
  

  .dialog_content-children{
    padding: 24px 20px 10px 20px;
  }
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}

