.modal {
  min-width: 346px;
  max-width: 400px !important;
  max-height: 500px;
  overflow: auto;
  z-index: 20 !important;
  position: fixed !important;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-radius: 4px;
  outline: none;
  &__closeicon {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    cursor: pointer;
  } 
  &__wrapper {
    padding: 34px 20px;
    &-field {
      padding: 12px 0px;
      label {
        font-size: 13px;
        color: #192A53;
        font-weight: 500;
        &.error {
          color: #ff5454;
        }
      }
      span[name=label] {
        display: inline-block;
        padding-bottom: 5px;
        font-size: 13px;
        color: #192A53;
        font-weight: 500;
        &.error {
          color: #ff5454;
        }
      }
      div[name=radio] {
        width: 150px;
        padding-top: 10px;
      }
    }
    &-pickers {
      display: flex;
      position: relative;
      justify-content: space-between;
      .picker__wrapper {
        width: 43%;
      }
      .picker__hr {
        position: absolute;
        top: 8px;
        left: calc(50% - 10px);
        hr {
          width: 20px;
          height: 2px;
          background-color: #192A53;
        }
      }
    }
    &-label {
      font-size: 13px;
        color: #192A53;
        font-weight: 500;
    }
    &-error {
      color: #ff5454;
    }
    &-errortext {
      display: inline-block;
      color: #ff5454;
      padding-top: 5px;
      padding-left: 10px;
      font-size: 13px;
    }
    &-radiogroup {
      display: flex;
      width: 150px;
      padding-top: 7px;
      & label {
        padding-left: 5px
      }
    }
  }
}

.modal {
  width: 350px;
  max-height: 500px;
  overflow: auto;
  position: absolute;
  left: 50%;
  background-color: #ffffff;
  z-index: 10;
  border-radius: 4px;
  outline: none;
  &__closeicon {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    background-color: #F9F9F9;
    cursor: pointer;
  } 
  &__wrapper {
    padding: 34px 20px;
  }
}

.grey-screen {
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0.8;
  z-index: 15;
  position: fixed;
  background-color: #b3b3b3a8;
}